<template>
    <div class="botmain">
        <div class="botcontent">
            <div class="bbleft">
                <div class="aitalkbox">
                    <div class="talkmainbox">
                        <div class="sharetop">
                            <div class="sleft">
                                <img
                                    :src="botinfo.profilePicture == 'default.png' ? require('@/views/Home/components/img/morenhead.png') : botinfo.profilePicture" />
                            </div>
                            <div class="sright">
                                <div class="srtitle">您正在跟用户{{ phone.substr(0, 3) + '****' + phone.substr(7, 4)
                                    }}创建的机器人对话
                                </div>
                                <div class="srcontent">{{ botinfo.name }}</div>
                            </div>
                        </div>
                        <span v-if="!fistalk">
                            <div class="aibotbox">
                                <div class="aibottxt">
                                    <div class="txttit">
                                        我是您的{{ botinfo.name }}智能AI机器人
                                    </div>
                                    <div class="txtcont">{{ botinfo.introduce }}</div>
                                </div>
                            </div>
                            <div class="bbvaluebox">
                                <div class="bbitem" v-for="(item, i) in aibox.cjlist" :key="i">
                                    <div class="bbileft" @click="gotk(item.title)"><img :src="item.img" /></div>
                                    <div class="bbiright" @click="gotk(item.title)">
                                        <p>{{ item.title }}</p>
                                        <div class="queitem" v-if="si < 2" @click="gotalk(sitem, item)" :key="si"
                                            v-for="(sitem, si) in item.qlist">
                                            {{ si + 1 }}.{{ sitem }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                        <div v-else class="mmain" id="mmain">
                            <span v-for="(item, i) in talklist" :key="i">
                                <div class="useritem">
                                    <div class="userhead">
                                        <img src="./Home/components/img/morenhead.png" alt="">
                                    </div>
                                    <div class="userright">
                                        <div class="usertext">{{ item.message }}</div>
                                    </div>
                                </div>
                                <div class="aiitem">
                                    <div class="aihead">
                                        <img :src="botinfo.profilePicture == 'default.png' ? require('@/views/Home/components/img/morenhead.png') : botinfo.profilePicture"
                                            alt="">
                                    </div>
                                    <div class="airight">
                                        <div class="airesultimg"
                                            v-if="item.reply_content && (item.reply_content.imagereplys || item.reply_content.imagereply)">
                                            <!-- <img v-for="imgitem in item.reply_content.resultimgs" :key="imgitem" :src="imgitem" /> -->
                                            <el-image
                                                v-for="imgitem in (item.reply_content.imagereplys || [item.reply_content.imagereply])"
                                                :key="imgitem"
                                                :src="imgitem.replace('http://47.96.179.72:11454/', '/klgapi/')"
                                                :preview-src-list="srcList">
                                            </el-image>
                                        </div>
                                        <div class="aitext">
                                            <vue-markdown :source="item.reply_content.result"
                                                class="custom-markdown"></vue-markdown>
                                            <div class="stopans" v-if="isstop && (i == talklist.length - 1)"
                                                @click="stopans">停止生成</div>
                                        </div>
                                        <div class="zhuitalk"
                                            v-if="isshowcw && item.reply_content.relatedquestion && item.reply_content.relatedquestion.length > 0">
                                            <div class="zhuititle">猜你想问：</div>
                                            <div class="zhuiitem"
                                                v-for="(zitem, zi) in item.reply_content.relatedquestion"
                                                @click="zhuiitemclick(zitem)" :key="zi">{{ zitem }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="bottombox">

                    <div style="width: 100%;">
                        <div class="inputbox" v-if="!isaudio">
                            <img src="@/assets/images/yy.png" alt="" @click="audioclick">
                            <!-- <img src="../assets/img/yy.png" alt="" @click="stopRecording"> -->
                            <div class="center"><input id="qmess" v-on:keydown="keydown" outline="none"
                                    placeholder="点我开始智问…" />
                            </div>
                            <button id="sendbtn" @click="sendmessage()"><img src="@/assets/images/zqzs/send.png"
                                    alt=""></button>

                        </div>
                        <div class="inputbox" v-else>
                            <input id="qmess" outline="none" type="hidden" placeholder="点我开始智问…" />
                            <img src="@/assets/images/yy.png" alt="" @click="audioclick">
                            <div class="audiotip" v-if="!islu" @click="handleStart">轻触开始语音输入</div>
                            <div class="audiotiping" v-else @click="handleStop">已录制{{
                                        recorder.duration.toFixed(2) }}秒，轻触结束</div>
                            <div></div>
                        </div>
                    </div>

                </div>
                <div class="tipbox">
                    <!-- <div class="canquenumbox"><img src="@/assets/images/quenum.png" />可提问数<span> {{ canquenum }} /
                            5</span></div> -->
                    <div class="createbot" @click="gologin"><img src="@/assets/images/addbot.png" />创建机器人</div>
                </div>

            </div>
        </div>
        <el-dialog title="提示" :modal-append-to-body="false" :visible="tipflag" :before-close="handleClose">
            <div class="textbox">
                您的试用权限已经用完，请登录享受更多服务
            </div>
            <div class="btnbox">
                <div class="btnitem goxt" @click="gologin">前往登录</div>
                <div class="btnitem skip" @click="tipflag = false">取消</div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import Recorder from 'js-audio-recorder'
import router from '@/router'
import { Base64 } from 'js-base64';
import { baseUrl } from '@/axios/baseUrl'
import { formatTime } from '@/utils'
import { countmybot, getbotinfo } from '@/api/bot'
// import { SpeechRecognition } from 'speech-recognition';
// 创建一个新的AudioContext对象
// const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
// let mediaRecorder = null; // 存储录音器对象
export default {
    name: '',
    components: { VueMarkdown },
    data(props) {
        return {
            fistalk: false,
            aitype: '英鸣产业大模型',
            talklist: props.basetalklist || [],
            hotlists: [],
            conversation_id: null,
            tipflag: false,
            anslist: '',
            aibox: { botid: 0 },
            canquenum: 555555,
            cjqlist: [],
            recorder: null,
            isstop: false,
            isaudio: false,
            islu: false,
            recording: false, // 是否正在录音
            audioSrc: null, // 存储录音的URL
            isconn: false,
            isshowcw: false,
            eventSource: null,
            cjed: '',
            srcList: [],
            cided: 0,
            hisflag: false,
            talkguanlianid: 0,
            phone: '',
            schhistxt: '',
            historylist: [],
            botinfo: []
            // modetype:
        }
    },
    props: {
    },
    mounted() {
        // console.log('33', Base64.decode(this.$router.history.current.query.s).split('|'))
        const svalue = Base64.decode(this.$router.history.current.query.s).split('|')
        this.aibox.botid = svalue[0]
        this.phone = svalue[1]
        if (localStorage.getItem('canquenum')) {
            this.canquenum = localStorage.getItem('canquenum')
        } else {
            localStorage.setItem('canquenum', this.canquenum)
            this.countbot()
        }
        setTimeout(() => {
            this.getbotinfo(svalue[0])
        }, 200);
    },
    watch: {

    },
    beforeDestroy() {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = null
        }
    },
    created() {
        let _this = this;
        window.zhuiitemclick = _this.zhuiitemclick;
    },
    methods: {
        getbotinfo(id) {
            getbotinfo({ id: id }).then(res => {
                console.log(res)
                this.botinfo = res.data
            })
        },
        gologin() {
            this.$router.push('/login')
        },
        handleClose() {
            this.tipflag = false
        },
        openhis() {
            this.hisflag = true
            this.fistalk = true;
            console.log(this.historylist)
            if (this.historylist.length == 0) {
                this.fetchhistorydata()
            }

        },
        countbot() {
            countmybot({ id: this.aibox.botid }).then(res => {
            })
        },

        createtalk() {
            const that = this;
            that.talklist = []
            that.cided = 0;
            that.fistalk = false
            that.hisflag = false
            // })
        },
        closehisbox() {
            this.hisflag = false
        },

        keydown(event) {
            // console.log(event)
            if (event.keyCode === 13 && event.shiftKey) {
                // console.log("按t下了回车键+shift");
                // document.getElementById('qmess').value = document.getElementById('qmess').value + '<br />'
                // event.preventDefault(); // 阻止默认的换行行为

            } else if (event.keyCode === 13) {
                // console.log("按t下了回车键");
                setTimeout(() => {
                    this.sendmessage()
                }, 100)
            }
        },
        audioclick() {
            this.isaudio = !this.isaudio
        },
        aitypeclick(e) {
            this.aitype = e
        },

        cjclick(item) {
            console.log(item);

            // this.fistalk = true;
            this.cjqlist = item.qlist || [];
            this.cjed = item.title
        },
        // 开始录音
        handleStart() {
            this.recorder = new Recorder()
            Recorder.getPermission().then(() => {
                console.log('开始录音')
                this.islu = true;
                this.recorder.start() // 开始录音
            }, (error) => {
                this.$message({
                    message: '请先允许该网页使用麦克风',
                    type: 'info'
                })
                console.log(`${error.name} : ${error.message}`)
            })
        },

        handleStop() {
            console.log('停止录音')
            this.islu = false;
            this.recorder.stop() // 停止录音
            setTimeout(() => {
                this.uploadRecord()
            }, 200);
        },

        uploadRecord() {
            if (this.recorder == null || this.recorder.duration === 0) {
                this.$message({
                    message: '请先录音',
                    type: 'error'
                })
                return false
            }
            this.recorder.pause() // 暂停录音
            this.timer = null
            console.log('上传录音')// 上传录音
            let xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl + '/gptapi/audioapi/asr', true);
            const formData = new FormData()
            const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
            // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
            const newbolb = new Blob([blob], { type: 'audio/wav' })
            const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
            formData.append('file', fileOfBlob)
            // const url = window.URL.createObjectURL(fileOfBlob)
            // this.src = url
            xhr.send(formData);
            const that = this;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const res = JSON.parse(xhr.responseText);
                    console.log(res)
                    setTimeout(() => {
                        document.getElementById('qmess').value = res.text;
                        that.sendmessage()
                    }, 100);
                }
            }

        },
        go1269() {
            window.open(this.aibox.appcode == 'jiaju' ? 'http://lead.entra.guanyingis.com/login' : 'http://industry-ai.web.shuzhiren.com/#/industrialBrain/jiangxisheng')
        },
        backcj() {
            this.fistalk = false
            const element = document.getElementById('mmain')
            element.scrollTo({
                x: 0,
                top: 0,
                behavior: 'smooth'
            })
        },

        gotalk(e, item) {
            console.log(e, item)
            if (item) this.cjed = item.title;
            setTimeout(() => {
                document.getElementById('qmess').value = e;
                this.sendmessage()
                this.cjclick(item)
            }, 500);
            this.fistalk = true;
            this.$emit('changeaiflag', true)

        },
        gotk(e) {
            this.cjed = e
            this.fistalk = true;
            this.$emit('changeaiflag', true)
        },
        changegpttype(e) {
            // console.log(e)
            this.aitype = e

        },
        gofurpage() {
            // router.push({ name: 'Furniture' })
        },
        async connectSever(input) {
            // const eventSource = new EventSource(`http://47.96.179.72:11454/chat/gygpt_chat?query=${input}&model_name=${this.aitype || '英鸣产业大模型'}&temperature=0.7&mode=绿色食品GPT`, {
            //   headers: {
            //     'Accept': 'text/event-stream '
            //   }
            // })
            // if (localStorage.getItem('canquenum') == 0) {
            //     this.tipflag = true;
            //     return;
            // }
            const that = this;
            that.talklist[that.talklist.length - 1].reply_content.result = '正在思考...'
            console.log('进来了几次')

            if (this.talkguanlianid == 0) {
                that.eventSource = new EventSource(`${baseUrl}/api/industryWisdomAsk/askByStreamVisitor?robotId=${this.aibox.botid}&query=${input}&isVisitor=true`)
            } else {
                that.eventSource = new EventSource(`${baseUrl}/api/industryWisdomAsk/askByStreamVisitor?robotId=${this.aibox.botid}&query=${input}&robotQAHistoryId=${this.talkguanlianid}&isVisitor=true`)
            }

            that.eventSource.addEventListener('open', (event) => {
                that.isconn = true;
                console.log('连接已建立', JSON.stringify(event))

            })
            that.eventSource.onmessage = (event) => {
                that.isstop = true;
                // console.log(event.data)
                const data = JSON.parse(event.data)
                console.log(data)
                if (!data) {
                    that.talklist[that.talklist.length - 1].reply_content.result = '正在思考...'
                    return
                }
                if (that.talklist[that.talklist.length - 1].reply_content.result == '正在思考...') {

                    that.talklist[that.talklist.length - 1].reply_content.result = ''
                }
                that.isshowcw = true
                if (data.answer == '[DONE]') {
                    that.talklist[that.talklist.length - 1].reply_content.relatedquestion = data.relatedquestion;
                    return;
                }
                if (data.id) {
                    this.talkguanlianid = data.id
                    return;
                }

                // that.anslist = that.anslist + data.answer

                that.talklist[that.talklist.length - 1].reply_content.imagereplys = data.imagereplys ? data.imagereplys : data.imagereply ? [data.imagereply] : null
                that.talklist[that.talklist.length - 1].reply_content.result = that.talklist[that.talklist.length - 1].reply_content.result + data.answer


                if (data.answer.includes('\n') || that.talklist[that.talklist.length - 1].reply_content.result.length / 40 == 0 || data.answer.includes('图') || data.answer.includes('，')) {
                    that.$nextTick(() => {
                        const element = document.getElementById('mmain')
                        element.scrollTo({
                            x: 0,
                            top: Number.MAX_SAFE_INTEGER,
                            behavior: 'smooth'
                        })
                    })
                }


            }
            that.eventSource.addEventListener('eventName', (event) => {
                console.log('Received event:', event.data);
            });
            that.eventSource.onerror = (event) => {
                that.isconn = false;
                this.canquenum = this.canquenum - 1;
                localStorage.setItem('canquenum', this.canquenum)
                // if (this.canquenum == 0) {
                //     this.tipflag = true;
                // }
                console.log('连接已断开')
                console.log(that.srcList)
                that.isstop = false;
                that.isshowcw = false;
                that.$nextTick(() => {
                    that.isshowcw = true;
                    setTimeout(() => {
                        const element = document.getElementById('mmain')
                        element.scrollTo({
                            x: 0,
                            top: Number.MAX_SAFE_INTEGER,
                            behavior: 'smooth'
                        })
                    }, 300);


                })
                // setTimeout(() => {

                //   that.isshowcw = false
                // }, 1000);    .replace('http://47.96.179.72:11454/','/klgapi/')
                console.log(that.talklist[that.talklist.length - 1].reply_content)
                if (that.talklist[that.talklist.length - 1].reply_content.imagereplys) {
                    that.talklist[that.talklist.length - 1].reply_content.imagereplys.forEach(item => {
                        that.srcList.push(item.replace('http://47.96.179.72:11454/', 'https://gpt-all.web.guanyingis.com/klgapi/'))
                    })
                    // that.srcList.push(...that.talklist[that.talklist.length - 1].reply_content.resultimgs)
                }
                console.log(that.srcList)
                that.eventSource.close()
            }
        },
        stopans() {
            // this.eventSource.stop()
            this.eventSource.close()
            this.isstop = false
            this.isshowcw = false;
            this.isconn = false;
        },
        zhuiitemclick(e) {
            console.log(e)
            this.gotalk(e)
        },
        sendmessage() {
            // if (this.canquenum == 0) {
            //     this.tipflag = true;
            //     return;
            // }
            // document.getElementsByClassName('aibotbox')[0].style.display = 'none'
            // document.getElementsByClassName('aitalkbox')[0].style.height = 'calc(100% - 20px)'
            // document.getElementsByClassName('aihotbox')[0].style.marginTop = '150px'
            if (this.isconn) {
                return;
            }
            if (!this.fistalk) {
                this.fistalk = true
            }
            console.log('first')
            const message = document.getElementById('qmess').value
            if (message == '') return;
            this.talklist.push({
                message: message,
                reply_content: {
                    result: ''
                }
            })
            const element = document.getElementById('mmain')
            setTimeout(() => {
                element.scrollTo({
                    x: 0,
                    top: element.scrollHeight,
                    behavior: 'smooth'
                })

                document.getElementById('qmess').value = ''
            }, 300)
            this.$emit('talklistchange', this.talklist)
            this.connectSever(message);

        },
    }
}
</script>
<style lang='less' scoped>
.botmain {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #0b0c1b;

    .bottop {
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;

        .botleft {
            width: calc(100% - 100px);
            display: flex;
            flex-wrap: wrap;
            padding: 10px 20px;
            background-color: white;

            border-radius: 8px;

            .typeitem {
                width: 180px;
                height: 60px;
                display: flex;
                color: #fff;
                font-size: 20px;
                text-align: center;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                img {
                    height: 25px;
                    margin-right: 10px;
                }
            }

            .typeed {
                color: white;
                background-color: #263754;
                border-radius: 10px;
            }
        }

        .gpttypebox {
            width: 100%;
            display: none;
        }

        .bottopright {
            width: 80px;
            height: 80px;
            background-color: white;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 32px;
            }
        }

        @media (max-width: 998px) {

            .gpttypebox {
                width: 100%;
                display: flex;
                width: calc(100% - 100px);
                height: 80px;
                display: flex;
                flex-wrap: wrap;
                padding: 10px 20px;
                background-color: white;

                border-radius: 8px;

                /deep/ .el-dropdown {
                    width: 100%;
                }

                /deep/ .el-button--primary {
                    height: 100%;
                    width: 100%;
                    color: white;
                    background-color: #263754;
                    font-size: 20px;
                }
            }

            .botleft {
                display: none;
            }
        }
    }


    .aitalkbox {
        width: calc(100% - 0px);
        height: calc(100% - 50px);
        padding-bottom: 20px;
        border-radius: 8px;
        margin-bottom: 20px;
        transition: height 0.3s;
        position: absolute;
        bottom: 38px;
        left: 0;

        .talkcjlist {
            width: 100%;
            height: 50px;
            background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
            box-shadow: -1px -1px 2px #1E213B;
            border-radius: 5px;
            display: flex;
            flex-wrap: wrap;

            .qlist {
                width: 100%;
                height: 71px;
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                // align-content: center;
                // align-items: center;
                overflow-y: auto;

                .talkcjqlist {
                    background-color: #2d3269;
                    margin-left: 20px;
                    height: 30px;
                    margin-top: 5px;
                    color: #d0d0ff;
                    font-size: 14px;
                    cursor: pointer;
                    padding: 5px 28px;
                    border-radius: 8px
                }

                .talkcjqlist:hover {
                    color: #ff009d;
                    border: 1px solid #ff009d;
                }
            }


            .talkcjitem {
                height: 100%;
                line-height: 50px;
                color: #727272;
                font-size: 18px;
                width: 150px;
                text-align: center;
                cursor: pointer;
                font-weight: bolder;

            }

            .talked {
                color: #ff009d;
            }
        }

        .talkmainbox {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            .mmain {
                width: 100%;
                height: calc(100% - 189px);
                overflow-y: auto;


            }

            .sharetop {
                box-shadow: 1px 0px 6px #1E213B;
                background: #141732;
                height: 120px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .sleft {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    img {
                        width: 60px;
                    }
                }

                .sright {
                    padding-left: 20px;
                    color: #fff;

                    .srtitle {
                        font-size: 18px;
                        margin: 10px 0;
                    }

                    .srcontent {
                        font-size: 18px;
                        color: #f2f2f2;
                    }
                }
            }
        }



        .endline {
            border-bottom: 1px solid #c6ccc6;
            height: 20px;
            text-align: center;
            margin-bottom: 20px;
        }


        .bg1 {
            background-color: #f6683d10 !important;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #ffffff;
    }

    // /*水平的整个滚动条*/

    // ::-webkit-scrollbar:horizontal {
    //   height: 24px;
    //   background-color: transparent;
    // }

    /*滚动条轨道*/





    .aiitem {
        display: flex;
        width: 100%;
        padding: 20px;

        .aihead {
            width: 80px;
            height: 80px;
            padding-left: 10px;
            margin-right: 10px;
            font-size: 18px;

            img {
                width: 60px;
                margin-left: -10px;
                border-radius: 50%;
            }
        }

        .airight {
            min-width: 180px;
            max-width: calc(100% - 180px);
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-left: 10px;
            text-align: left;
            margin-bottom: 20px;
            // padding-bottom: 20px;
            background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
            box-shadow: 0px 0px 6px #1E213B;
            border-radius: 0px 12px 12px 12px;
            position: relative;




            .airesultimg {
                width: 400px;
                margin: 20px;
                margin-bottom: 0;

                img {
                    height: 300px;
                }
            }

            .name {
                font-size: 18px;
                color: #fff;
                margin-bottom: 10px;

                span {
                    font-size: 12px;
                    background-color: #ebf0f3;
                    border-radius: 6px;
                    padding: 4px 8px;
                    margin-left: 10px;
                }
            }


            .aitext {
                // width: 500px;
                width: 100%;
                color: #fff;
                padding: 10px 20px;
                font-size: 18px;

                .stopans {
                    font-size: 14px;
                    height: 50px;
                    line-height: 50px;
                    cursor: pointer;
                    color: #ff009d;
                }

                .custom-markdown a {
                    color: red;
                    word-break: break-all;
                    /* 修改a标签的颜色 */
                }

                .custom-markdown code,
                .custom-markdown pre,
                .custom-markdown pre code {
                    white-space: break-spaces;
                    word-break: break-all;
                    /* 换行 */
                }

                .custom-markdown {
                    word-break: break-all;
                    /* 换行 */
                }

                .custom-markdown p {
                    white-space: break-spaces;
                    word-break: break-all;
                    /* 换行 */
                }




                .talkhotitem {
                    min-width: 550px;
                    height: 40px;
                    background-color: white;
                    border: 1px solid #2b3f60;
                    margin: 5px 20px;
                    font-size: 20px;
                    text-align: center;
                    line-height: 40px;
                    cursor: pointer;
                }

                .talkhotitem:hover {
                    background-color: #2b3f60;
                    color: white;
                }
            }

            .zhuitalk {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .zhuititle {
                    width: 100%;
                    color: #FF009D;
                    font-size: 18px;
                    padding: 10px 20px;
                }

                .zhuiitem {
                    max-width: calc(33% - 20px);
                    background: #2D3269;
                    border-radius: 19px;
                    font-family: Alibaba PuHuiTi;
                    font-weight: 500;
                    font-size: 14px;
                    color: #D0D0FF;
                    text-align: center;
                    padding: 10px 20px;
                    margin: 10px 10px;
                    overflow: hidden;
                    /* 确保文本超出容器时会被裁剪 */
                    white-space: nowrap;
                    /* 确保文本在一行内显示 */
                    text-overflow: ellipsis;
                    /* 使用省略号表示文本溢出 */
                    cursor: pointer;
                }

            }


        }
    }

    .useritem {
        color: white;
        width: calc(100% - 40px);
        margin: 20px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;

        .userhead {
            width: 80px;
            height: 80px;
            padding-left: 10px;
            margin-right: 10px;
            font-size: 18px;
            letter-spacing: 2px;

            img {
                width: 80px;
                margin-left: -10px;
                border-radius: 50%;
                padding: 10px;
            }
        }

        .userright {

            // background: #FF009D;
            padding-right: 10px;
            text-align: left;
            float: right;
            margin: 10px 0;
            max-width: 800px;

            .usertext {
                background: #FF009D;
                color: white;
                box-shadow: 0px 0px 6px #1E213B;
                border-radius: 12px 0px 12px 12px;
                font-size: 16px;
                padding: 10px 20px;
                margin-top: 10px;
            }
        }
    }
}

.botcontent {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 0px);

    .bbleft {
        width: calc(100% - 0px);
        margin: 0 auto;
        height: calc(100% - 0px);
        position: relative;

        .go1269 {
            position: absolute;
            cursor: pointer;
            right: 0px;
            top: 20px;
            color: #0988f6;
            font-size: 20px;
        }

        .bbtitle {
            font-size: 32px;
            color: #fff;
            font-weight: bold;
            padding: 10px 20px;
        }

        .bbvaluebox {
            display: flex;

            flex-wrap: wrap;
            margin-top: 30px;
            width: 100%;

            .bbitem {
                width: calc(50% - 24px);
                display: flex;
                background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
                box-shadow: -1px -1px 2px #1E213B;
                opacity: 1;
                border-radius: 8px;
                border: 1px solid #1E213B;
                margin: 12px;
                padding: 20px;
                cursor: pointer;

                .bbileft {
                    width: 150px;

                    img {
                        width: 100%;
                        border: 1px solid #ff009d80;
                        border-radius: 8px;
                        padding: 10px;
                    }
                }

                .bbiright {
                    width: calc(100% - 150px);
                    padding: 0 20px;
                    padding-top: 0;
                    font-size: 16px;
                    color: #fff;
                    text-align: left;
                    margin: 0 auto;

                    p {
                        font-size: 24px;
                        font-weight: 600;
                        color: #ff009d;
                        margin: 10px 0;
                        text-align: left;
                        margin-bottom: 20px;
                    }

                    .queitem {
                        color: #fff;
                        margin-top: 10px;
                        cursor: pointer;
                    }

                    .queitem:hover {
                        color: #ff009d;
                        text-decoration: underline
                    }
                }
            }
        }

        .bbright {
            width: 100%;
            border-radius: 8px;
            display: flex;
            flex-wrap: wrap;

            .hotitem {
                background-color: #1c223608;
                color: #fff;
                font-size: 18px;
                margin: 10px;
                border-radius: 20px;
                padding: 10px 5px;
                padding-left: 60px;
                width: calc(33% - 18px);
                justify-content: space-between;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                cursor: pointer;

                .hoticon {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 16px;
                    background-color: orange;
                    color: white;
                    position: absolute;
                    left: 15px;
                    bottom: 10px;
                }

                .h1 {
                    background-color: red;
                }

                .h2 {
                    background-color: rgb(134, 134, 134);
                }
            }

            .bg1 {
                background-color: #F6683D10 !important
            }
        }

        .tipbox {
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            position: absolute;
            left: 0;
            bottom: 65px;

            .canquenumbox {
                height: 40px;
                background: #FFFFFF60;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                padding: 0 20px;
                border-radius: 8px;
                border: 1px solid #0062FF;
                color: #fff;

                span {
                    color: #003b98;
                    padding-left: 5px;
                    font-weight: bold
                }

                img {
                    width: 20px;
                    margin-right: 8px
                }
            }

            .createbot {
                height: 40px;
                background: linear-gradient(228deg, #AE0EF0 0%, #F81B9A 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                padding: 0 20px;
                border-radius: 8px;
                margin-left: 20px;
                color: #fff;
                cursor: pointer;

                img {
                    width: 20px;
                    margin-right: 8px
                }
            }
        }

        .bottombox {
            width: 100%;
            height: 80px;
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;

            .hisbtn {
                height: 80px;
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                background: #181B39;
                box-shadow: 0px 0px 2px rgba(124, 130, 180, 0.13);
                border-radius: 8px;
                cursor: pointer;

                img {
                    width: 30px;
                    height: 30px;
                }
            }

            .hisbig {
                height: 80px;
                width: 300px;
                background: transparent;
                box-shadow: none;
                z-index: -1;
            }

            .inputbox {
                width: 100%;
                height: 80px;
                display: flex;
                padding: 10px 20px;
                background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
                box-shadow: 1px 0px 6px #1E213B;
                border-radius: 12px;
                justify-content: center;
                align-items: center;

                img {
                    width: 45px;
                    height: 45px;
                    margin: 0 10px;
                    cursor: pointer;
                }

                button {
                    background: transparent;
                    border: 0;
                }

                .audiotip {
                    width: calc(100% - 30px);
                    background-color: #f2f2f2;
                    color: #444;
                    height: 70%;
                    border-radius: 20px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 14px;
                    letter-spacing: 5px;
                }

                .audiotiping {
                    width: calc(100% - 30px);
                    background-color: #f2f2f2;
                    color: #444;
                    height: 70%;
                    border-radius: 20px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 14px;
                }


                .center {
                    width: calc(100% - 110px);

                    input {
                        width: 100%;
                        height: 100%;
                        border: 0;
                        outline: none;
                        color: #fff;
                        font-size: 20px;
                        background-color: transparent;
                    }

                    input:focus {
                        outline: none;
                    }

                    input::placeholder {
                        color: #fff;
                        font-weight: 100;
                    }
                }
            }
        }

        .aibotbox {
            width: 100%;
            display: flex;
            padding: 20px;


            .aibotimg {
                cursor: pointer;
                width: 130px;
                height: 130px;
                position: relative;

                img {
                    position: absolute;
                    left: -10px;
                    top: -20px;
                    width: 140px;
                }
            }

            .aibottxt {
                padding: 10px;

                .txttit {
                    font-size: 32px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #FF009D;
                    line-height: 45px;
                }

                .txtcont {
                    font-size: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #fff;
                    line-height: 25px;
                    margin-top: 20px;
                }
            }
        }
    }


}


@media (max-width: 998px) {
    .botmain {

        .aitalkbox {
            .talkmainbox {
                .mmain {
                    .useritem .userright {
                        margin-top: 0;
                        margin-bottom: 10px;
                    }

                    .aiitem .airight .aitext {
                        max-width: 100%;
                    }

                    .useritem .userright .usertext,
                    .aiitem .airight .aitext {
                        font-size: 16px;
                        margin-top: 10px;
                    }


                    .aiitem .airight {
                        max-width: calc(100% - 100px);
                    }

                    .useritem .userhead,
                    .aiitem .aihead {
                        width: 60px;
                        height: 60px;


                        img {
                            width: 60px;
                        }
                    }
                }
            }

        }
    }

    /deep/ .el-dialog {
        width: 350px !important;
    }
}

/deep/ a {
    color: #ff009d !important
}

/deep/ pre {
    white-space: break-spaces;
    word-break: break-all;
}

/deep/ .el-dialog {
    background: linear-gradient(180deg, #0e1341 0%, #181A37 100%);
    box-shadow: 3px 3px 3px rgba(17, 19, 38, 0.82);
    border-radius: 13px;
    width: 550px;

    .textbox {
        width: 100%;
        display: flex;
        height: 100px;
        align-items: center;
        font-size: 15px;
        color: #FF009D;
        background-color: #121443;
        justify-content: center;
        text-align: center
    }

    .btnbox {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .btnitem {
            width: 100px;
            height: 35px;
            color: #FF009D;
            border: 1px solid #FF009D;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
            border-radius: 3px;
            margin-left: 20px;
            cursor: pointer;
        }

        .skip {
            color: #0e6dee;
            border: 1px solid #0e6dee;
        }
    }



}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #FF009D !important;
    font-size: 22px
}
</style>
